import React from "react";
import Layout from "../components/Layout";
import Paper from "../components/Paper";

const Page = () => (
  <Layout fullgreen title="Merchant Terms & Conditions">
    <Paper>
      <div className="page-header">
        <h1>Merchant Terms &amp; Conditions</h1>
        <p>Effective date: 21/08/2021</p>
      </div>
      <div className="page-content numbered-headers">
        <h2>Introduction</h2>
        <p>
          These Terms form part of the Merchant Enrolment Form for Online
          Ordering Services ("Form"). The Merchant hereby agrees and
          acknowledges that the Services under the Form and the Terms are
          provided by INHB Supply Services DMCC with operating name SupplyMe
          (“SupplyMe”), a company duly established under the laws of U.A.E.
          (“Company”, “we”, “our”, “us”).
        </p>
        <p>
          The Merchant agrees that upon signature and delivery of the Form by
          the Merchant, the Form and the Terms constitute a legally binding
          agreement made between you, whether personally or on behalf of an
          entity, SupplyMe regarding use of SupplyMe Platform (as defined below)
          for the provision of selling items directly from the Merchant to
          customers on the Platform.
        </p>

        <h2>Definitions</h2>
        <ol className="ul-romain">
          <li>
            <strong>"Customer"</strong> means users who place Orders through the
            Platform;
          </li>
          <li>
            <strong>"Customer Application"</strong> means the proprietary online
            website and/or mobile-based Order placement application of SupplyMe
            available on the Platform.
          </li>
          <li>
            <strong>"Electronic Payment Mechanism"</strong> means the online and
            mobile-based payment mechanisms including the third-party payment
            gateways, credit card/debit card/net banking transfer and/or
            e-wallets and/ or SupplyMe credits that are available on the
            Platform for the purposes of facilitating the payment of the Order
            Value by the Customer;
          </li>
          <li>
            <strong>"Execution Date"</strong> means the date of execution of
            Form;
          </li>
          <li>
            <strong>"Form"</strong> means the Merchant Enrolment Form for Online
            Ordering Services executed by the Merchant;
          </li>
          <li>
            <strong>"Information"</strong> means the information set out and
            provided along with the Form and includes any information which is
            supplied by the Merchant to SupplyMe under these Terms such as the
            price lists, operating hours, rates at which the taxes are charged
            by the Merchant to the Customer, delivery areas serviced by the
            Merchant and/or delivery terms, specific information the Merchant is
            under an obligation to supply to SupplyMe (i) immediately on the
            Execution Date; and (ii) within 1 (one) day from any change in such
            information;
          </li>
          <li>
            <strong>"Merchant"</strong> means the entity/individual being the
            legal owner/operator of the company mentioned in the Form;
          </li>
          <li>
            <strong>"Merchant Application"</strong> means the SupplyMe developed
            online and/or mobile-based Order management application;
          </li>
          <li>
            <strong>“Net Order Value”</strong> means Order Value received, less
            the Commission Percentage and any other amount, charges, etc., that
            are due to SupplyMe from the Merchant under these Terms or the Form.
          </li>
          <li>
            <strong>"Net Sales"</strong> means the gross amount charged by the
            Merchant to any Customer that is attributable to any Order placed
            through the SupplyMe Platform, less all applicable taxes, and
            discounts being offered by the merchant on the SupplyMe Platform (if
            any).
          </li>
          <li>
            <strong>"Order"</strong> means the placement of the Order by the
            Customer with the Merchant via the Platform;
          </li>
          <li>
            <strong>"Order Fulfilment Fee"</strong> means the fee payable by the
            Merchant to SupplyMe for the delivery services and as more fully set
            out in the Form.
          </li>
          <li>
            <strong>"Order Value"</strong> means the amount which is payable by
            the Customer for the placement of an Order with the Merchant on the
            Platform;
          </li>
          <li>
            <strong>"Parties"</strong> means SupplyMe and the Merchant;
          </li>
          <li>
            <strong>"Platform"</strong> means the Website and Customer
            Application owned by SupplyMe;
          </li>
          <li>
            <strong>"Platform Fee"</strong>: the amount payable by the Merchant
            to SupplyMe as set out in the Form; being a percentage of the Net
            Sales and fixed fee for each Order;
          </li>
          <li>
            <strong>"Services"</strong> means the following services offered by
            SupplyMe to the Merchant, on and from the Effective Date, for the
            provision of selling goods by the Merchant to the Customer through
            the Platform:
            <ol>
              <li>
                listing of goods and the price list by SupplyMe supplied by the
                Merchant;
              </li>
              <li>
                Order placement mechanism by SupplyMe for the purchase of goods
                by the Customer from the Merchant on a real-time basis;
              </li>
              <li>
                payment mechanism for the payment of the Order Value by the
                Customer provided by SupplyMe over the Platform;
              </li>
            </ol>
          </li>
          <li>
            <strong>"Website"</strong> means www.supplyme.ae (including the
            webpages contained or hyperlinked therein and owned or controlled by
            SupplyMe), and such other media or media channels, devices, mobile
            applications, software, or technologies as SupplyMe may choose from
            time to time.
          </li>
        </ol>

        <h2>Our Obligations:</h2>
        <ol className="ul-romain">
          <li>
            SupplyMe will (i) list Merchant's goods and the price list on the
            Platform and (ii) transfer to the Merchant, the amounts received
            from the Customers in accordance with agreed Terms set out herein;
          </li>
          <li>
            SupplyMe will display on the Platform, on a best effort basis, all
            necessary information provided by the Merchant. However, SupplyMe is
            not under any obligation to display any information until the
            Merchant provides all required information and such information is
            in compliance with SupplyMe’s policies and guidelines;
          </li>
          <li>
            SupplyMe will transmit the Orders placed by the Customer with the
            Merchants.
          </li>
          <li>
            SupplyMe will redress the Customers and the Merchant’s complaints in
            respect of the functioning of the Platform.
          </li>
          <li>
            SupplyMe may suspend the Merchant’s account if the Merchant is found
            non-compliant with the applicable laws, rules, regulations,
            licenses, standards and guidelines, applicable to related services.
          </li>
        </ol>

        <h2>Merchant’s Obligations</h2>
        <ol className="ul-romain">
          <li>
            Merchant will ensure that the Information provided to SupplyMe is
            current, including but not limited to Merchant name, address,
            contact telephone number, email id, manager/contact person details,
            delivery times, price lists, and other relevant information.
            SupplyMe shall not be held responsible for any incorrect information
            provided by the Merchant.
          </li>
          <li>Merchant shall process and execute the Order(s) promptly.</li>
          <li>
            Merchant shall inform SupplyMe about any change or modification made
            to the Order by the Customer, directly with the Merchant;
          </li>
          <li>
            In the event Merchant undertakes delivery of any Order, it shall
            ensure only trained personnel undertakes such delivery and the
            safety of the items is not compromised at any time until the Order
            is received by the Customer. The Merchant agrees to indemnify
            SupplyMe for any claims that may arise on account of the delivery of
            Order undertaken by the Merchant.
          </li>
          <li>
            The Merchant warrants that items provided to Customers are of high
            quality and shall be at the highest standards following the practice
            related to each industry, ensuring that the relevant products are in
            stock and available for delivery.
          </li>
          <li>
            Merchant shall solely be responsible for the fulfilment of Customer
            Orders or the delivery of such Orders to Customers where the
            Merchant provides its own logistics;
          </li>
          <li>
            Merchant shall not engage in any fraudulent activity or misuse any
            benefits extended by SupplyMe to the Customers and shall be liable
            to SupplyMe in such event;
          </li>
        </ol>

        <h2>Fees and Payments</h2>
        <ol className="ul-romain">
          <li>
            In consideration for the Services offered by SupplyMe to the
            Merchant, the Merchant undertakes to pay to SupplyMe commission at
            the rates set out in the Form. This commission amount may be
            determined by way of a percentage of the amount of each Order and a
            fixed charge for each Order ("Platform Fee").
          </li>
          <li>
            The Merchant acknowledges and agrees that any Order Value which is
            collected by SupplyMe for, and on behalf of, the Merchant in
            accordance with these Terms, shall be passed on by SupplyMe to the
            Merchant subject to the deduction of the following (as applicable)
            amounts by SupplyMe from such Order Payment ("Settlement Process"):
            <ol>
              <li>Platform fee as per the Form</li>
              <li>Registration Fee</li>
              <li>Annual Renewal Fee</li>
            </ol>
          </li>
          <li>
            any amount that is payable and due by Merchant to SupplyMe hereunder
            or under any other agreement between Merchant and SupplyMe and/or
            its affiliates.
          </li>
          <li>
            The Parties acknowledge and agree that the Settlement Process and
            Net Order Value due to the Merchant shall be remitted by SupplyMe
            within a period of 7 bank working days from the day of delivery of
            the Order.
          </li>
          <li>
            Merchant agrees that we may choose to offer discounts against his
            orders. In such event, SupplyMe will include a discount on the
            invoice raised from the Merchant to the Customer and will pay for
            this discount by adjusting our commission.
          </li>
          <li>
            Merchant shall be under an obligation to pay refund charges of the
            Orders in case of any.
          </li>
        </ol>

        <h2>License</h2>
        <p>
          Merchant hereby grants us a non-exclusive, royalty-free,
          sub-licensable, transferrable, assignable, perpetual and worldwide
          licence to use your trademarks and other intellectual property you may
          provide to us in order for us to provide the Services to our end users
          and as may be otherwise required under the Form. This includes, but is
          not limited to, a) use of the Merchant’s name in the context of Google
          adwords to support advertising and promotional campaigns to promote
          online ordering on the internet which may be undertaken by SupplyMe;
          b) preparation of derivative works of, or incorporate into other
          works, all or any portion of the marketing materials which will be
          made by SupplyMe for the purposes of its business. Any material the
          Merchant transmits or submits to SupplyMe either through the Platform
          or otherwise ("Material"), unless specifically mentioned as
          “confidential”, shall be considered and may be treated by SupplyMe as
          non-confidential, subject to SuppltMe’s obligations under relevant
          data protection legislation. The Merchant also grants to SupplyMe a
          royalty-free, perpetual, irrevocable, non-exclusive licence to use,
          copy, modify, adapt, translate, publish and distribute world-wide any
          Material for the purposes of providing services under these Terms or
          to or for the purposes of advertising and promotion of the Platform.
          The Merchant agrees that all information provided to SupplyMe that is
          published, may be relied upon and viewed by Customers to enable them
          to make decisions and constitute a legally binding contract.
        </p>

        <h2>Warranties, Representations and Undertakings</h2>
        <ol className="ul-romain">
          <li>
            Merchant warrant, represent and undertake that:
            <ol>
              <li>
                you have full power and authority to enter into these Terms and
                you shall, at all times, fully comply with all applicable laws,
                statutes and regulations, including, without limitation: (i)
                anti-bribery, anti-corruption, export control and sanctions
                laws; (ii) consumer protection laws; (iii) import regulations,
                including with respect to type approvals; and (iv) customs
                duties and other applicable taxes;
              </li>
              <li>
                you have all the necessary licenses, permissions,
                authorizations, proprietary rights, consents and permits in the
                products you list or which are listed on your behalf and to sell
                and promote these products in the UAE;
              </li>
              <li>
                your listed products conform to the required quality and safety
                standards in the UAE;
              </li>
              <li>
                you are solely responsible for any liability arising from the
                purchase and use of your listed products by Platform users or
                other third parties;
              </li>
              <li>
                you own or have the authority to grant the licenses granted to
                us by you under these Terms and any content you submit as part
                of your use of the Services and any products that you list do
                not violate the rights of any third party anywhere in the world
                including, without limitation, any intellectual property rights
                (whether registered or not); and
              </li>
            </ol>
          </li>
          <li>
            Furthermore, whilst we attempt to be as accurate as possible, we do
            not warrant that product descriptions or other content of any
            Service is accurate, complete, reliable, current, or error-free.
          </li>
        </ol>

        <h2>Confidentiality</h2>
        <ol className="ul-romain">
          <li>
            Other than for the provision of Service(s) by SupplyMe, SupplyMe
            does not share any information of the Merchant with third parties
            unless requisitioned by government authorities.
          </li>
          <li>
            Other than for the purpose of availing Service(s) from SupplyMe, the
            Merchant must not disclose any confidential information about
            SupplyMe, including but not limited to its business strategies,
            pricing, revenues, expenses, and Order information to third parties.
          </li>
        </ol>

        <h2>Liability</h2>
        <ol className="ul-romain">
          <li>
            Nothing in these Terms shall limit or exclude a party’s liability:
            (a) for fraud, including fraudulent misrepresentation, perpetrated
            by that party; (b) for death or personal injury caused by the
            negligence of that party; or (c) for any other liability that cannot
            be limited or excluded under applicable law.
          </li>
          <li>
            Subject to clause ‎8.1, in no event will SupplyMe, subsidiaries and
            affiliates, and our and their directors, officers, agents,
            employees, suppliers, subcontractors or licensors be liable, whether
            based on an action or claim in contract, tort, negligence, breach of
            statutory duty or otherwise arising out of or in relation to these
            Terms for loss of profits, loss of data or information, business
            interruption or other pecuniary loss or for any special, indirect,
            incidental or consequential damages, even if we, our affiliates,
            directors, officers, agents, employees, licensors, subcontractors or
            suppliers have been advised of the possibility of such damages.
          </li>
          <li>
            In addition, to the extent permitted by applicable law, we
            (including our parent company, subsidiaries and affiliates and our
            and their directors, officers, agents, employee, suppliers,
            subcontractors or licensors) are not liable, and you agree not to
            hold us responsible, for any damages or losses resulting directly or
            indirectly from:
            <ol>
              <li>
                the content or other information you provide when using the
                Services;
              </li>
              <li>
                your use of or your inability to use our Services and/or delays
                or disruptions in our Services;
              </li>
              <li>
                pricing, shipping, format or other guidance and information
                provided by us or used for product listings;
              </li>
              <li>
                any defects or damage to a product that occurred prior to our
                acknowledged receipt of your product;
              </li>
              <li>
                bugs, errors or inaccuracies of any kind in our Services,
                viruses or other malicious software obtained by accessing or
                linking to our Services;
              </li>
              <li>
                damage to your hardware device from the use of our Platform;
              </li>
              <li>
                the content, actions or inactions of third parties using our
                Services;
              </li>
              <li>
                a suspension or other action taken by us with respect to your
                use of the Services;
              </li>
              <li>
                the duration or manner in which your listings appear in search
                results; or
              </li>
              <li>
                your need to modify practices, content or behaviour or your loss
                of or inability to do business as a result of changes to these
                Terms.
              </li>
            </ol>
          </li>
          <li>
            Subject to clause ‎8.1, if clauses ‎8.2 or ‎8.3 are held to be
            unenforceable or inapplicable for any reason, then the total
            liability applicable to us, subsidiaries and affiliates and our and
            their directors, officers, agents, employee, suppliers,
            subcontractors or licensors, to you, (including reasonable legal
            fees), whether based on an action or claim in contract, negligence
            or breach of statutory duty or otherwise, arising out of or in
            relation to these Terms shall be limited to the lower of, (a) the
            price the product sold for on our Platform and its original shipping
            costs; (b) the amount of fees in dispute not to exceed the total
            fees that you paid to us in the three (3) months prior to the action
            giving rise to the liability.
          </li>
          <li>
            You agree to indemnify and hold us, subsidiaries and affiliates and
            our and their directors, officers, agents, employee, suppliers,
            subcontractors or licensors harmless from and against any losses,
            damages and expenses (including legal fees and attorney’s fees)
            (“Claims”) arising out of or relating to:
            <ol>
              <li>
                any claims or demands made by any third party (including
                Customers) due to or arising out of your use of the Services;
              </li>
              <li>
                your violation of any of provisions of these Seller Terms,
                including, without limitation, any of the warranties,
                representations and undertakings;
              </li>
              <li>
                your violation of any applicable laws, including, without
                limitation, data protection or anti-spam laws as well as any
                anti-bribery, anti-corruption, export control and sanctions
                laws;
              </li>
              <li>
                your listed products, including with respect to defects in the
                products, losses suffered by third parties arising from the use
                of your products; or
              </li>
              <li>
                the manner in which you use our Services, including, without
                limitation, that the content you post, the products you list or
                trademarks infringe the intellectual property rights of any
                third party or that the content of your listings is slanderous,
                defamatory, obscene or violates any other rights (including
                privacy rights) of any third party (including other Platform
                users).
              </li>
            </ol>
          </li>
          <li>
            In the event that you breach or fail to comply with any of your
            obligations as set out in these Terms, you hereby agree that we
            reserve the right to disclose your information to third parties,
            including but not limited to, government or regulatory authorities
            and/or registered trademark owners, in order to resolve any issues
            arising from such breach.
          </li>
        </ol>

        <h2>Suspension and Termination of Account</h2>
        <ol className="ul-romain">
          <li>
            Without prejudice to any of our rights and remedies and without any
            liability to you, we may limit, suspend or withdraw your access to
            the Services and/or remove hosted content submitted by you or on
            your behalf, if we consider, at our sole discretion that: (a) you
            have breached these Terms in any manner whatsoever; (b) you are not
            reasonably cooperating with an investigation by us or any law
            enforcement or regulatory agency; or (c) our continued provision of
            Services to you would expose us or other Platform users to
            regulatory action or other material risks. Upon termination of your
            account, your seller account registration shall cease to exist.
          </li>
          <li>
            Termination of these Terms (howsoever occasioned) shall not: (a)
            affect any accrued rights or liabilities of either party; (b) affect
            any provision of these Terms that is expressly or by implication
            intended to continue on or after termination; or (c) require a court
            order.
          </li>
        </ol>

        <h2>Modification</h2>
        <ol className="ul-romain">
          <li>
            SupplyMe may modify these Terms from time to time, and any such
            changes will (i) be reflected on the Website, and (ii) be effective
            immediately upon the changes being reflected on the Platform. The
            Merchant agrees to be bound to any such changes or modifications and
            understands and accepts the importance of regularly reviewing these
            Terms as updated on the Platform.
          </li>
          <li>
            Further, in the event SupplyMe upgrades, modifies or replaces the
            Services ("Service Modifications") offered to the Merchant, SupplyMe
            will notify the Merchant prior to making the same and give the
            Merchant the opportunity to review and comment on the Service
            Modifications before continuing to use the Service or any
            alternative service offered by SupplyMe. The Service Modifications
            will also be reflected on the SupplyMe Platform. If the Merchant
            continues to use the Service or any alternate service offered by
            SupplyMe, following any notice of the Service Modifications, it
            shall constitute the Merchant’s acceptance of such Service
            Modifications.
          </li>
        </ol>
      </div>
    </Paper>
  </Layout>
);

export default Page;
